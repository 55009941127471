import React from 'react';
import Triangle, { CornerTypes } from 'components/Triangle/Triangle';
import styled, { theme } from 'styles/styled';
import ContentTitle from 'components/ContentTitle';
import Text from 'components/Text';
import Image from 'components/Image';
import { useIsRtl } from 'hooks/useCurrentLocale';

import Create from 'images/create-step.svg';
import Buy from 'images/buy-step.svg';
import Mine from 'images/mine-step.svg';
import Earn from 'images/earn-step.svg';

import { useTranslations } from 'settings/i18n';
import {
  StepsWrapper,
  Step,
  StepNumber,
  StepNumberTitle,
  TitleWrapper,
  ImageWrapper,
  Container,
  MediumTopTriangle,
  ContentTitleWrapper,
  FirstImageWrapper,
  SecondImageWrapper,
} from './styled';

const StepsContentTitle = styled(ContentTitle)`
  align-self: flex-start;
  margin: 0 auto 60px auto;

  @media ${theme.devices.xlarge} {
    font-size: 62px;
    margin-bottom: 0;
  }
`;

const Steps = () => {
  const t = useTranslations();
  const isRtl = useIsRtl();

  return (
    <Container>
      <Triangle
        width="60%"
        height="167px"
        zIndex={101}
        breakpoint="xlarge"
        cornerType={CornerTypes['top-right']}
        color={theme.palette.primaryShade1}
        position={{
          right: 0,
          top: 0,
        }}
      />
      <MediumTopTriangle>
        <Triangle
          width="101%"
          height="107px"
          zIndex={101}
          cornerType={CornerTypes['top-right']}
          color={theme.palette.primaryShade1}
          position={{
            right: 0,
            top: 0,
          }}
        />
      </MediumTopTriangle>
      <Triangle
        width="100%"
        height="167px"
        zIndex={101}
        cornerType={CornerTypes['bottom-left']}
        color={theme.palette.primaryShade1}
        position={{
          left: 0,
          bottom: 0,
        }}
      />
      <Triangle
        width="120%"
        height="70px"
        showOnMobile
        zIndex={101}
        cornerType={CornerTypes.right}
        color={theme.palette.primaryShade1}
        position={{
          left: 0,
          top: '35px',
        }}
      />
      <Triangle
        width="120%"
        height="70px"
        showOnMobile
        zIndex={101}
        cornerType={CornerTypes.left}
        color={theme.palette.primaryShade1}
        position={{
          right: 0,
          bottom: '35px',
        }}
      />
      <ContentTitleWrapper>
        <StepsContentTitle isRtl={isRtl} title={t('sectionTitle')} />
      </ContentTitleWrapper>
      <StepsWrapper>
        <Step>
          <FirstImageWrapper>
            <ImageWrapper>
              <Image image={Create} alt="Create" />
            </ImageWrapper>
          </FirstImageWrapper>
          <TitleWrapper>
            <StepNumber>1</StepNumber>
            <StepNumberTitle isRtl={isRtl}>{t('stepOneTitle')}</StepNumberTitle>
          </TitleWrapper>
          <Text isRtl={isRtl}>{t('stepOneText')}</Text>
        </Step>
        <Step>
          <SecondImageWrapper>
            <ImageWrapper>
              <Image image={Buy} alt="Buy" />
            </ImageWrapper>
          </SecondImageWrapper>
          <TitleWrapper>
            <StepNumber>2</StepNumber>
            <StepNumberTitle isRtl={isRtl}>{t('stepTwoTitle')}</StepNumberTitle>
          </TitleWrapper>
          <Text isRtl={isRtl}>{t('stepTwoText')}</Text>
        </Step>
        <Step>
          <ImageWrapper>
            <Image image={Mine} alt="Mine" />
          </ImageWrapper>
          <TitleWrapper>
            <StepNumber>3</StepNumber>
            <StepNumberTitle isRtl={isRtl}>
              {t('stepThreeTitle')}
            </StepNumberTitle>
          </TitleWrapper>
          <Text isRtl={isRtl}>{t('stepThreeText')}</Text>
        </Step>
        <Step>
          <ImageWrapper>
            <Image image={Earn} alt="Earn" />
          </ImageWrapper>
          <TitleWrapper>
            <StepNumber>4</StepNumber>
            <StepNumberTitle isRtl={isRtl}>
              {t('stepFourTitle')}
            </StepNumberTitle>
          </TitleWrapper>
          <Text isRtl={isRtl}>{t('stepFourText')}</Text>
        </Step>
      </StepsWrapper>
    </Container>
  );
};

export default Steps;
