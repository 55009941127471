import styled from 'styled-components';
import { theme } from 'styles/styled';

const Title = styled.div`
  color: ${theme.palette.primary};
  font-size: ${theme.fontSize.titleMobile};
  font-weight: ${theme.fontWeight.medium};
  line-height: 27px;
  letter-spacing: ${theme.letterSpacings.normal};

  @media ${theme.devices.medium} {
    font-size: ${theme.fontSize.title};
  }
`;

export default Title;
