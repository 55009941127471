import React from 'react';

const RealTwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32">
    <g fill="none">
      <circle cx="16" cy="16" r="16" fill="#55ACEE" />
      <path
        fill="#F1F2F2"
        d="M26 11.776a7.957 7.957 0 01-2.239.595 3.819 3.819 0 001.714-2.094 7.944 7.944 0 01-2.475.918A3.948 3.948 0 0020.154 10c-2.153 0-3.898 1.696-3.898 3.787 0 .297.034.585.101.863a11.176 11.176 0 01-8.035-3.957 3.689 3.689 0 00-.527 1.904c0 1.313.688 2.473 1.734 3.152a3.958 3.958 0 01-1.766-.475v.049c0 1.834 1.344 3.365 3.127 3.712a3.992 3.992 0 01-1.76.066c.496 1.504 1.935 2.599 3.641 2.63a7.968 7.968 0 01-4.842 1.62c-.314 0-.625-.017-.929-.052A11.263 11.263 0 0012.975 25c7.17 0 11.09-5.77 11.09-10.775 0-.164-.003-.327-.01-.49A7.784 7.784 0 0026 11.776z"
      />
    </g>
  </svg>
);

export default RealTwitterIcon;
