import styled from 'styles/styled';

export const OuterWrapper = styled.div`
  position: relative;
`;

export const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: 0 ${({ theme }) => theme.dimensions.spacingBase14 * 2}px;

  @media ${({ theme }) => theme.devices.medium} {
    flex-basis: 60%;
    padding-top: 50px;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    flex-basis: 50%;
    padding-top: 0;
  }

  p {
    max-width: 480px;
    letter-spacing: ${({ theme }) => theme.letterSpacings.normal};
    position: relative;
    font-size: ${({ theme }) => theme.fontSize.labelBig};

    @media ${({ theme }) => theme.devices.xlarge} {
      font-size: ${({ theme }) => theme.fontSize.copy};
    }

    @media ${({ theme }) => theme.devices.xxlarge} {
      font-size: ${({ theme }) => theme.fontSize.base};
    }

    &:after {
      content: '';
      position: absolute;
      top: 8px;
      left: -16px;
      width: 4px;
      height: 36px;
      background-color: ${({ theme }) => theme.palette.primary};
      @media ${({ theme }) => theme.devices.medium} {
        left: -25px;
        width: 7px;
        height: 50px;
      }

      @media ${({ theme }) => theme.devices.xlarge} {
        left: -44px;
      }
    }
  }
`;

export const TopTextWrapper = styled(TextWrapper)`
  align-items: flex-start;

  @media ${({ theme }) => theme.devices.medium} {
    padding-left: 130px;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    padding-left: 160px;
  }

  @media ${({ theme }) => theme.devices.xxlarge} {
    padding-left: 15%;
  }
`;

export const BottomTextWrapper = styled(TextWrapper)`
  align-items: flex-start;

  @media ${({ theme }) => theme.devices.medium} {
    padding: 85px 80px 50px 35px;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    padding-left: 100px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex-basis: 50%;
  padding: 40px 0 60px 0;
  width: 100%;

  @media ${({ theme }) => theme.devices.medium} {
    padding-top: 35px;
    flex-basis: 40%;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    padding: 0;
    flex-basis: 50%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  @media ${({ theme }) => theme.devices.medium} {
    flex-direction: row;
    align-items: flex-start;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    align-items: center;
  }
`;

export const TopContentWrapper = styled(ContentWrapper)`
  flex-direction: column-reverse;

  @media ${({ theme }) => theme.devices.medium} {
    flex-direction: row;
  }

  @media ${({ theme }) => theme.devices.medium} and (max-width: 1200px) {
    align-items: flex-end;

    ${ImageWrapper} {
      padding: 0;
      bottom: -35px;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;

  @media ${({ theme }) => theme.devices.xlarge} {
    margin-left: -45px;
  }

  @media ${({ theme }) => theme.devices.xlarge} and (max-width: 1400px) {
    h2 {
      margin-bottom: 15px;
      font-size: ${({ theme }) => theme.fontSize.heading};
    }
  }
`;

export const TopTitleWrapper = styled(TitleWrapper)`
  align-self: flex-start;
  width: 100%;
`;

export const BottomTitleWrapper = styled(TitleWrapper)`
  align-self: flex-start;
  max-width: 500px;
  width: 100%;
`;
