import React from 'react';

import Header from 'components/Header';
import Footer from 'components/Footer';

interface LayoutProps {
  children: JSX.Element | React.ReactChildren[] | JSX.Element[];
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
