import React, { useState } from 'react';
import FilledButton, { Skew } from 'components/Button/FilledButton';
import Triangle, { CornerTypes } from 'components/Triangle/Triangle';
import SocialMediaIconLink from 'components/Footer/SocialMediaIconLink';
import RealInstagramIcon from 'images/Icons/RealInstagramIcon';
import RealTelegramIcon from 'images/Icons/RealTelegramIcon';
import RealTwitterIcon from 'images/Icons/RealTwitterIcon';
import RealYoutubeIcon from 'images/Icons/RealYoutubeIcon';
import RealFacebookIcon from 'images/Icons/RealFacebookIcon';
import ChevronIcon from 'images/Icons/ChevronIcon';
import { theme } from 'styles/styled';
import socialMediaLinks from 'settings/social-media-links';
import { useTranslations } from 'settings/i18n';
import menuLinks from 'settings/menuLinks';
import useRedirectToSavedOrBrowserLocale from 'hooks/useRedirectToSavedOrBrowserLocale';

import { useIsRtl } from 'hooks/useCurrentLocale';

import {
  Container,
  InnerWrapper,
  IconsWrapper,
  SectionTitle,
  ChevronWrapper,
  Chevron,
} from './styled';

const StartSection = () => {
  useRedirectToSavedOrBrowserLocale();

  const [isStartSectionHidden, setIsStartSectionHidden] = useState(false);
  const t = useTranslations();
  const togetherWeGrow = t('togetherWeGrow');
  const title = togetherWeGrow.split(' ');
  const firstWord = title.shift();
  const toggleHide = () => setIsStartSectionHidden(!isStartSectionHidden);

  const isRtl = useIsRtl();

  return (
    <Container isStartSectionHidden={isStartSectionHidden}>
      <InnerWrapper>
        <SectionTitle isRtl={isRtl}>
          <span>{firstWord}</span>
          {title.join(' ')}
        </SectionTitle>
        <FilledButton
          isRtl={isRtl}
          target="_blank"
          rel="noopener noreferrer"
          skew={Skew['top-right']}
          as="a"
          style={{
            width: '90%',
            margin: '16px auto 0 auto',
          }}
          href={menuLinks.signUp}>
          {t('startNow')}
        </FilledButton>
        <IconsWrapper>
          <SocialMediaIconLink
            href={socialMediaLinks.instagram}
            aria-label="Instagram link">
            <RealInstagramIcon />
          </SocialMediaIconLink>
          <SocialMediaIconLink
            href={socialMediaLinks.twitter}
            aria-label="Twitter link">
            <RealTwitterIcon />
          </SocialMediaIconLink>
          <SocialMediaIconLink
            href={t('telegramLink')}
            aria-label="Telegram link">
            <RealTelegramIcon />
          </SocialMediaIconLink>
          <SocialMediaIconLink
            href={socialMediaLinks.youtube}
            aria-label="YouTube link">
            <RealYoutubeIcon />
          </SocialMediaIconLink>
          <SocialMediaIconLink
            href={socialMediaLinks.facebook}
            aria-label="Facebook link">
            <RealFacebookIcon />
          </SocialMediaIconLink>
        </IconsWrapper>
      </InnerWrapper>
      {!isStartSectionHidden && (
        <>
          <Triangle
            color={theme.palette.light}
            cornerType={CornerTypes['bottom-right']}
            width="101.5%"
            height="60px"
            position={{
              right: 0,
              top: '-59px',
            }}
          />
          <Triangle
            color={theme.palette.light}
            cornerType={CornerTypes['top-right']}
            width="101.5%"
            height="60px"
            position={{
              right: 0,
              bottom: '-59px',
            }}
          />
        </>
      )}
      <ChevronWrapper>
        <Triangle
          color={theme.palette.primary}
          cornerType={CornerTypes.custom}
          customClipPath="polygon(70% 1%, 100% 0, 100% 100%, 0 96%)"
          width="90px"
          height="100%"
          position={{
            left: '-80px',
            bottom: 0,
          }}
        />
        <Chevron
          isStartSectionHidden={isStartSectionHidden}
          onClick={toggleHide}>
          <ChevronIcon />
        </Chevron>
      </ChevronWrapper>
    </Container>
  );
};

export default StartSection;
