import React from 'react';

const RealInstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32">
    <defs>
      <linearGradient id="prefix__a" x1="50%" x2="50%" y1="99.709%" y2=".777%">
        <stop offset="0%" stopColor="#E09B3D" />
        <stop offset="30%" stopColor="#C74C4D" />
        <stop offset="60%" stopColor="#C21975" />
        <stop offset="100%" stopColor="#7024C4" />
      </linearGradient>
      <path id="prefix__b" d="M0 0L18 0 18 18 0 18z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle
        cx="16"
        cy="16"
        r="16"
        fill="url(#prefix__a)"
        fillRule="nonzero"
      />
      <g transform="translate(7 7)">
        <mask id="prefix__c" fill="#fff">
          <use xlinkHref="#prefix__b" />
        </mask>
        <path
          fill="#FFF"
          d="M16.403 13.033a3.374 3.374 0 01-3.37 3.37H4.967a3.374 3.374 0 01-3.37-3.37V4.968a3.374 3.374 0 013.37-3.371h8.066a3.374 3.374 0 013.37 3.37v8.066zM13.033 0H4.967A4.974 4.974 0 000 4.968v8.065A4.973 4.973 0 004.967 18h8.066A4.972 4.972 0 0018 13.033V4.968A4.973 4.973 0 0013.033 0z"
          mask="url(#prefix__c)"
        />
        <path
          fill="#FFF"
          d="M9 12.041A3.044 3.044 0 015.959 9 3.044 3.044 0 019 5.959 3.044 3.044 0 0112.041 9 3.044 3.044 0 019 12.041m0-7.679A4.643 4.643 0 004.362 9 4.642 4.642 0 009 13.638 4.642 4.642 0 0013.638 9 4.643 4.643 0 009 4.362M13.832 3.008a1.175 1.175 0 00-1.17 1.17c0 .309.125.61.343.83.217.217.52.342.827.342.31 0 .61-.125.83-.342a1.175 1.175 0 000-1.657 1.18 1.18 0 00-.83-.343"
        />
      </g>
    </g>
  </svg>
);

export default RealInstagramIcon;
