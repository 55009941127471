import React from 'react';
import styled from 'styles/styled';

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  img {
    width: 100%;
    z-index: 10;
    height: 100%;
  }
`;

interface Props {
  image: string;
  alt: string;
}

const Image = ({ image, alt }: Props) => {
  return (
    <ImageWrapper>
      <img src={image} alt={alt} />
    </ImageWrapper>
  );
};
export default Image;
