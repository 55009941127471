import styled from 'styles/styled';
import Title from 'components/Title';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 150px 36px;

  @media ${({ theme }) => theme.devices.medium} {
    padding: 100px;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    padding: 150px 72px;
  }
`;

export const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.devices.medium} {
    flex-direction: row;
    padding: 100px 0;
  }

  @media ${({ theme }) => theme.devices.medium} and (max-width: 800px) {
    justify-content: center;
  }

  @media ${({ theme }) => theme.devices.xxlarge} {
    max-width: 1360px;
  }
`;

export const TitleWrapper = styled(Title)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  min-height: 75px;
  width: 100%;

  h3 {
    font-size: ${({ theme }) => theme.fontSize.title};
    line-height: 27px;
    text-transform: uppercase;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-basis: 20%;
  flex-direction: column;
  min-width: auto;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
  }

  ${TitleWrapper} {
    align-self: flex-start;
  }

  h3 {
    padding-left: 16px;
  }

  @media ${({ theme }) => theme.devices.medium} {
    margin-bottom: 0;
    min-width: 300px;

    h3 {
      padding-left: 32px;
    }

    p {
      padding-left: 88px;
    }
  }
  @media ${({ theme }) => theme.devices.large} {
    min-width: 400px;
  }

  @media ${({ theme }) => theme.devices.xxlarge} {
    min-width: 300px;

    &:nth-of-type(2) {
      h3 {
        max-width: 200px;
      }
    }
  }

  @media (min-width: 1440px) and (max-width: 1500px) {
    min-width: 285px;
  }
`;

export const FirstImageWrapper = styled.div`
  transform: scale(1.3);
`;

export const SecondImageWrapper = styled.div`
  transform: scale(1.15);
`;

export const ImageWrapper = styled.div`
  max-height: 200px;
  align-self: center;
  display: flex;
  width: 100%;
  height: 200px;
  margin-bottom: ${({ theme }) => theme.dimensions.basePadding}px;

  @media ${({ theme }) => theme.devices.medium} {
    align-self: auto;
    padding-left: 75px;
  }
`;

export const StepNumber = styled.div`
  align-self: flex-start;
  text-align: center;
  padding: 6px 13px;
  max-width: 44px;
  position: relative;
  top: 15px;
  border: 2px solid ${({ theme }) => theme.palette.primary};
  @media ${({ theme }) => theme.devices.medium} {
    margin-left: 12px;
  }
`;

export const StepNumberTitle = styled.h3<{
  isRtl: boolean;
}>`
  width: 100%;
  direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};
`;

export const MediumTopTriangle = styled.div`
  @media ${({ theme }) => theme.devices.medium} {
    display: block;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    display: none;
  }
`;

export const ContentTitleWrapper = styled.div`
  max-width: 1360px;
  width: 100%;
`;
