import useWindowSize from 'hooks/useWindowSize';
import { theme } from 'styles/styled';

const useIsMobile = () => {
  const { width } = useWindowSize();

  return width < theme.deviceSize.large;
};

export default useIsMobile;
