import { navigate } from 'gatsby';
import detectBrowserLanguage from 'detect-browser-language';

import {
  DEFAULT_LOCALE,
  generateLinkToTheNewLocale,
  useLanguages,
} from 'settings/i18n';
import useCurrentLocale from 'hooks/useCurrentLocale';
import { useLocation } from 'hooks/useLocation';

export default function useRedirectToSavedOrBrowserLocale() {
  const languages = useLanguages();
  const currentLocale = useCurrentLocale();
  const location = useLocation();

  // We interfere only when user lands on the default locale.
  if (currentLocale !== DEFAULT_LOCALE) return;

  const browserLocale = languages.find((language) =>
    language.locale.toLowerCase().includes(detectBrowserLanguage?.()),
  )?.locale;

  const savedLanguage = localStorage.getItem('language');
  const savedLocale = savedLanguage
    ? languages.find((language) => language.locale === savedLanguage)?.locale
    : null;

  // If user has saved a locale (by using language switcher),
  // we redirect to that locale if it's not already current locale.
  if (savedLocale) {
    if (savedLocale !== currentLocale) {
      navigate(
        generateLinkToTheNewLocale(currentLocale, savedLocale, location),
      );
    }

    // User doesn't have a saved locale, so we check if they have
    // browser locale other than the current default locale,
    // and then redirect to it.
  } else if (browserLocale && browserLocale !== currentLocale) {
    navigate(
      generateLinkToTheNewLocale(currentLocale, browserLocale, location),
    );
  }
}
