import styled from 'styles/styled';
import SocialMediaIconLink from 'components/Footer/SocialMediaIconLink';

interface StartSectionProps {
  isStartSectionHidden: boolean;
}

export const Container = styled.div<StartSectionProps>`
  display: flex;
  justify-content: center;

  transition: 330ms ease-in-out right;

  @media ${({ theme }) => theme.devices.medium} {
    position: absolute;
    right: ${({ isStartSectionHidden }) =>
      isStartSectionHidden ? '-425px' : '0'};
    top: 50%;
    transform: translate(0, -50%);
  }
`;

export const InnerWrapper = styled.section`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.light};
  height: auto;

  @media ${({ theme }) => theme.devices.medium} {
    min-height: 360px;
    height: 100%;
    padding: 20px 100px;
  }

  a {
    text-align: center;
    margin-bottom: 8px;
  }
`;

export const SectionTitle = styled.h1<{ isRtl: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.headingMobile};
  max-width: 150px;
  align-self: center;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};
  span {
    display: block;
    color: ${({ theme }) => theme.palette.dark};
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }
  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.heading};
    max-width: 350px;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
  ${SocialMediaIconLink} {
    svg {
      width: 24px;
      height: 24px;
    }
    @media ${({ theme }) => theme.devices.medium} {
      svg {
        width: 32px;
        height: 32px;
      }
    }
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.dimensions.spacingBase16}px;
    }
  }
`;

export const ChevronWrapper = styled.div`
  position: absolute;
  top: 0;
  left: -10px;
  width: 10px;
  height: 100%;
`;

export const Chevron = styled.div<StartSectionProps>`
  width: 40px;
  z-index: 999;
  height: 50px;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 25px;
    height: 25px;
    ${({ isStartSectionHidden: isHide }) =>
      isHide
        ? { transform: 'rotateZ(90deg)' }
        : { transform: 'rotateZ(-90deg)' }};
  }
`;
