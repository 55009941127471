import React from 'react';
import styled from 'styles/styled';

export const Title = styled.h2<{ isRtl: boolean }>`
  display: block;
  letter-spacing: ${({ theme }) => theme.letterSpacings.normal};
  font-size: ${({ theme }) => theme.fontSize.pageTitleMobile};
  margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 4.4}px;
  width: 100%;

  direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};

  strong {
    color: ${({ theme }) => theme.palette.primary};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.heading};
    margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    font-size: ${({ theme }) => theme.fontSize.headingBig};
    margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 4.7}px;
  }
`;

interface Props {
  title?: string;
}

const ContentTitle = ({
  title,
  ...props
}: Props & Omit<JSX.IntrinsicElements['h2'], 'ref'> & { isRtl: boolean }) => (
  <Title dangerouslySetInnerHTML={{ __html: String(title) }} {...props} />
);

export default ContentTitle;
