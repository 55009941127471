import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import Triangle, { CornerTypes } from 'components/Triangle/Triangle';
import ContentTitle from 'components/ContentTitle';
import Text from 'components/Text';
import styled, { theme } from 'styles/styled';

import { useIsRtl } from 'hooks/useCurrentLocale';
import { useTranslations } from 'settings/i18n';
import {
  TopTextWrapper,
  BottomTextWrapper,
  ImageWrapper,
  ContentWrapper,
  TopContentWrapper,
  OuterWrapper,
  TopTitleWrapper,
  BottomTitleWrapper,
} from './styled';

export type ImageData = {
  sourceUrl: string;
};

const TopContentTitle = styled(ContentTitle)`
  margin-right: 0;

  @media ${theme.devices.medium} {
    margin-right: 75px;
  }
`;

const AboutSection = () => {
  const t = useTranslations();
  const { aboutUsImage, gregImage } = useStaticQuery(graphql`
    query AboutUsImages {
      aboutUsImage: imageSharp(
        fluid: { originalName: { in: "about-us-image-people.jpeg" } }
      ) {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      gregImage: imageSharp(
        fluid: { originalName: { in: "about-us-image-greg.jpg" } }
      ) {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `);

  const isRtl = useIsRtl();

  return (
    <>
      <OuterWrapper>
        <Triangle
          width="10%"
          height="100%"
          cornerType={CornerTypes['top-left']}
          color={theme.palette.primary}
          position={{
            left: 0,
            top: 0,
          }}
        />
        <TopContentWrapper>
          <TopTextWrapper>
            <TopTitleWrapper>
              <TopContentTitle isRtl={isRtl} title={t('oldAboutUsTitle')} />
            </TopTitleWrapper>
            <Text isRtl={isRtl}>{t('oldAboutUsContent')}</Text>
          </TopTextWrapper>
          <ImageWrapper>
            <Triangle
              width="20%"
              height="101%"
              zIndex={101}
              cornerType={CornerTypes['top-left']}
              color={theme.palette.light}
              position={{
                left: '-1px',
                top: '-5px',
              }}
            />
            <Triangle
              width="20%"
              height="100%"
              zIndex={101}
              cornerType={CornerTypes['top-left']}
              color={theme.palette.light}
              breakpoint="xlarge"
              position={{
                left: '-1px',
                top: '-5px',
              }}
            />
            <Triangle
              width="100%"
              height="56px"
              showOnMobile
              cornerType={CornerTypes.left}
              color={theme.palette.primary}
              zIndex={100}
              position={{
                right: 0,
                top: '12px',
              }}
            />
            <Triangle
              width="100%"
              height="56px"
              showOnMobile
              cornerType={CornerTypes.left}
              color={theme.palette.primary}
              zIndex={100}
              position={{
                bottom: '32px',
                right: 0,
              }}
            />
            <Img
              fluid={{
                ...aboutUsImage.fluid,
                aspectRatio: 1.77,
              }}
              alt="Two smiling women"
            />
          </ImageWrapper>
        </TopContentWrapper>
      </OuterWrapper>
      <OuterWrapper>
        <Triangle
          width="10%"
          height="100%"
          cornerType={CornerTypes['top-right']}
          color={theme.palette.primaryLight}
          breakpoint="xlarge"
          position={{
            right: 0,
            top: 0,
          }}
        />
        <Triangle
          width="8%"
          height="90%"
          cornerType={CornerTypes['top-right']}
          color={theme.palette.primaryLight}
          position={{
            right: 0,
            top: '-1px',
          }}
        />
        <ContentWrapper>
          <ImageWrapper>
            <Triangle
              width="20%"
              height="102%"
              zIndex={101}
              cornerType={CornerTypes['bottom-right']}
              color={theme.palette.light}
              position={{
                right: '-1px',
                top: '0',
              }}
            />
            <Triangle
              width="100%"
              height="56px"
              showOnMobile
              cornerType={CornerTypes.right}
              color={theme.palette.primary}
              zIndex={100}
              position={{
                left: 0,
                top: '12px',
              }}
            />
            <Triangle
              width="100%"
              height="56px"
              showOnMobile
              cornerType={CornerTypes.right}
              color={theme.palette.primary}
              zIndex={100}
              position={{
                bottom: '32px',
                left: 0,
              }}
            />
            <Img
              fluid={{
                ...gregImage.fluid,
                aspectRatio: 1.77,
              }}
              alt="Greg Rogowski standing with microphone"
            />
          </ImageWrapper>
          <BottomTextWrapper>
            <BottomTitleWrapper>
              <ContentTitle isRtl={isRtl} title={t('oldAboutGregTitle')} />
            </BottomTitleWrapper>
            <Text isRtl={isRtl}>{t('oldAboutGregContent')}</Text>
          </BottomTextWrapper>
        </ContentWrapper>
      </OuterWrapper>
    </>
  );
};

export default AboutSection;
