import '@reach/dialog/styles.css';

import React from 'react';
import { Dialog } from '@reach/dialog';
import styled from 'styled-components';

import FilledButton, { Skew } from 'components/Button/FilledButton';
import TransparentButton from 'components/Button/TransparentButton';
import CloseIcon from 'images/Icons/CloseIcon';

import { theme } from 'styles/styled';
import useBodyScrollLock from 'hooks/useBodyScrollLock';
import usePersistentState from 'hooks/usePersistentState';
import useToggle from 'hooks/useToggle';

export const GeoPopupDialog = styled(Dialog)`
  position: relative;
  padding: 10px;
  padding-bottom: 25px;
  margin: 25px 10px 100px 10px;
  width: calc(100% - 20px);
  max-width: none;
  top: 20px;

  @media ${theme.devices.medium} {
    padding: 35px;
    padding-bottom: 55px;
    margin: 10% auto 0 auto;
    max-width: 770px;
  }
`;

const GeoPopupClose = styled(TransparentButton)`
  display: flex;
  margin-left: auto;

  :hover {
    cursor: pointer;
    opacity: 0.75;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.2px;
  margin-top: 24px;

  @media ${theme.devices.medium} {
    margin-top: 0;
  }
`;

const Description = styled.p`
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  margin-top: 24px;
  margin-bottom: 40px;

  @media ${theme.devices.medium} {
    font-size: 28px;
    line-height: 33px;
  }
`;

const Button = styled(FilledButton)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.2px;
  max-width: 195px;
  width: 100%;

  @media ${theme.devices.medium} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Link = styled(Title)`
  color: ${theme.palette.primary};
  font-size: 20px;
  line-height: 24px;

  @media ${theme.devices.medium} {
    font-size: 28px;
    line-height: 33px;
  }
`;

export default function GeoPopup() {
  useBodyScrollLock();

  const isUserGeoBlocked = useIsUserGeoBlocked();
  const [hasClosedGeoPopup, updateClosedGeoPopup] = usePersistentState<boolean>(
    'has_closed_geo_popup',
    false,
  );

  const modal = useToggle(isUserGeoBlocked && !hasClosedGeoPopup);

  if (modal.off) return null;

  return (
    <GeoPopupDialog>
      <GeoPopupClose
        type="button"
        onClick={() => {
          modal.setOff();
          updateClosedGeoPopup(true);
        }}>
        <CloseIcon color="black" />
      </GeoPopupClose>

      <div>
        <Title>Hello,</Title>
        <Description>
          If you are seeing this information it means Mining City services are
          currently not available in your country due to local regulations.
          <br />
          <br />
          Thank you for your understanding. If you have any questions related
          with it, please do not hesitate to email us at{' '}
          <Link as="a" href="mailto:support@miningcity.com">
            support@miningcity.com
          </Link>
        </Description>

        <Button
          isRtl={false}
          skew={Skew['none']}
          onClick={() => {
            modal.setOff();
            updateClosedGeoPopup(true);
          }}>
          Ok
        </Button>
      </div>
    </GeoPopupDialog>
  );
}

function useIsUserGeoBlocked(): boolean {
  const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || null;

  if (!timeZone) return false;
  return ['Europe/Warsaw'].includes(timeZone);
}
